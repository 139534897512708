<template>
  <div>
    <bread-crumb :crumbData="['店铺管理', '修改']" :chooseCrumbData="2" @close="onClose"></bread-crumb>

    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm"
      style="padding: 50px; width: 90%">
      <el-row>
        <el-col :span="9">
          <el-form-item label="店铺名称：" prop="storeName">
            <el-input v-model="ruleForm.storeName"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="9">
          <el-form-item label="联系姓名：" prop="name">
            <el-input v-model="ruleForm.name"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="9">
          <el-form-item label="联系电话：" prop="phone">
            <el-input v-model="ruleForm.phone"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="9">
          <el-form-item label="上午上班：" required>
            <!-- <el-select v-model="ruleForm.forenoonHour" style="width: 35%">
              <el-option
                v-for="item in forenoonHoures"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-select v-model="ruleForm.forenoonMin" style="width: 35%">
              <el-option
                v-for="item in forenoonMines"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select> -->
            <el-time-picker v-model="ruleForm.time1" :picker-options="{
              selectableRange: '00:00:00 - 12:00:00',
            }" placeholder="任意时间点">
            </el-time-picker>
          </el-form-item>
        </el-col>
        <el-col :span="9">
          <el-form-item label="下午下班：" required>
            <!-- <el-select v-model="ruleForm.afternoonHour" style="width: 35%">
              <el-option
                v-for="item in afternoonHoures"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-select v-model="ruleForm.afternoonMin" style="width: 35%">
              <el-option
                v-for="item in afternoonMines"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select> -->
            <el-time-picker v-model="ruleForm.time2" :picker-options="{
              selectableRange: '13:00:00 - 23:00:00',
            }" placeholder="任意时间点">
            </el-time-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="9">
          <el-form-item label="提现额度：">
            <el-input v-model="ruleForm.limit"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="9">
          <el-form-item label="最小起订量：">
            <el-input v-model="ruleForm.quantity"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="9">
          <el-form-item label="地区：" prop="location">
            <span style="font-size: 14px; margin-right: 10px">{{
              ruleForm.location
            }}</span>
            <!-- <el-button type="primary" @click="dialogVisible = true"
              >选择</el-button
            > -->
            <el-cascader style="width:100px" placeholder="请选择地区" :options="options" v-model="ruleForm.location"
              @change="ruleForm.location = ruleForm.location.join('')" />

          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="9">
          <el-form-item label="详细地址：" prop="address">
            <el-input v-model="ruleForm.address"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="9">
          <el-form-item label="排序：">
            <el-input v-model="ruleForm.sort"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="9">
          <el-form-item label="状态：" prop="status">
            <el-radio-group v-model="ruleForm.status">
              <el-radio label="待审核"></el-radio>
              <el-radio label="已审核"></el-radio>
              <el-radio label="已关闭"></el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="送达核销方式：">
            <el-select v-model="ruleForm.cancel" clearable placeholder="请选择">
              <el-option v-for="item in canceles" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="金币使用规则：">
            <textarea :rows="5" :cols="50" v-model="ruleForm.goldRule"></textarea>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">修改</el-button>
      </el-form-item>
    </el-form>

    <!-- <locationAdd
      v-if="dialogVisible"
      :dialogVisible="dialogVisible"
      @dialogVisible="onDialogVisible"
      @rowClick="onRowClick"
    ></locationAdd> -->
  </div>
</template>
<script>
import { pcaTextArr } from "element-china-area-data";
import locationAdd from "../../../../addAddress/location-add.vue";
import { getTime } from "../../../../../until/utilse";
export default {
  components: {
    locationAdd,
  },
  props: ["data"],
  data() {
    return {
      options: pcaTextArr,
      ruleForm: {
        storeName: "",
        name: "",
        phone: "",
        forenoonHour: "",
        forenoonMin: "",
        afternoonHour: "",
        afternoonMin: "",
        limit: "",
        quantity: "0",
        location: "",
        address: "",
        sort: "",
        status: "",
        cancel: "",
        time1: new Date(2023, 11, 24, 8, 0),
        time2: new Date(2023, 11, 24, 14, 0, 0),
        goldRule: "",
      },
      rules: {
        storeName: [{ required: true, message: "必填字段", trigger: "blur" }],
        name: [{ required: true, message: "必填字段", trigger: "blur" }],
        phone: [{ required: true, message: "必填字段", trigger: "blur" }],
        address: [{ required: true, message: "必填字段", trigger: "blur" }],
        location: [{ required: true, message: "必填字段", trigger: "change" }],
        status: [{ required: true, message: "必填字段", trigger: "change" }],
      },
      forenoonHoures: [],
      forenoonMines: [],
      afternoonHoures: [],
      afternoonMines: [],
      canceles: [{ label: "自动核销", value: 0 }],
      dialogVisible: false,
      id: "",
    };
  },
  mounted() {
    console.log(this.$props.data);
    const data = this.$props.data;
    const myTime1Min = this.startTime(data.work_star_time);
    const myTime1Sec = this.endTime(data.work_star_time);
    const myTime2Min = this.startTime(data.worl_end_time);
    const myTime2Sec = this.endTime(data.worl_end_time);
    this.id = data.id;
    this.ruleForm.storeName = data.shop_name;
    this.ruleForm.name = data.contact_name;
    this.ruleForm.phone = data.contact_phone;
    this.ruleForm.time1 = new Date(2023, 11, 24, myTime1Min, myTime1Sec);
    this.ruleForm.time2 = new Date(2023, 11, 24, myTime2Min, myTime2Sec);
    this.ruleForm.limit = data.withdarw_count;
    this.ruleForm.quantity = data.order_quantity;
    this.ruleForm.location = data.region;
    this.ruleForm.address = data.location;
    this.ruleForm.sort = data.sort;
    this.ruleForm.status = data.status;
    this.ruleForm.goldRule = data.gold_coin_rules;
    this.ruleForm.cancel = data.verification_method;
  },
  // watch: {
  //   "ruleForm.location"(n) {
  //     console.log(n);
  //   }
  // },
  computed: {},
  methods: {
    onClose(data) {
      this.$emit("close", data);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$http
            .post("/system/shop/save", {
              id: this.id,
              shop_name: this.ruleForm.storeName,
              work_star_time: getTime(this.ruleForm.time1),
              worl_end_time: getTime(this.ruleForm.time2),
              contact_name: this.ruleForm.name,
              contact_phone: this.ruleForm.phone,
              withdarw_count: this.ruleForm.limit,
              order_quantity: this.ruleForm.quantity,
              region: this.ruleForm.location,
              location: this.ruleForm.address,
              sort: this.ruleForm.sort,
              status: this.ruleForm.status,
              gold_coin_rules: this.ruleForm.goldRule,
              verification_method: this.ruleForm.cancel,
            })
            .then((res) => {
              if (res.data.status == "success") {
                this.$message({
                  message: res.data.msg,
                  type: "success",
                });
                this.$emit("close");
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    onDialogVisible(data) {
      this.dialogVisible = data;
    },
    startTime(date) {
      return String(date).charAt(0) == "0"
        ? String(date).charAt(1)
        : String(date).slice(0, 2);
    },
    endTime(date) {
      return String(date).charAt(3) == "0"
        ? String(date).charAt(4)
        : String(date).slice(3, 5);
    },
    onRowClick(location, address) {
      this.ruleForm.location = location;
      this.ruleForm.address = address;
    },
  },
};
</script>
<style scoped lang="less">
/deep/ .el-col {
  white-space: nowrap;
  font-size: 14px;
  margin: 0 5px;
  min-height: 32px;
}
</style>
