<template>
  <div>
    <bread-crumb
      :crumbData="['店铺管理', '添加店铺']"
      :chooseCrumbData="2"
      @close="onClose"
    ></bread-crumb>

    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
      style="padding: 50px; width: 40%"
    >
      <el-form-item label="店铺名称：" prop="storeName">
        <el-input v-model="ruleForm.storeName"></el-input>
      </el-form-item>
      <el-form-item label="所在地区：" prop="location">
        <span style="font-size: 14px; margin-right: 10px">{{
          ruleForm.location
        }}</span>
        <el-button type="primary" @click="dialogVisible = true">选择</el-button>
      </el-form-item>
      <el-form-item label="详细地址：" prop="address">
        <el-input v-model="ruleForm.address"></el-input>
      </el-form-item>
      <el-form-item label="排序：">
        <el-input v-model="ruleForm.sort"></el-input>
      </el-form-item>
      <el-form-item label="状态：" required>
        <el-radio-group v-model="ruleForm.status">
          <el-radio label="待审核"></el-radio>
          <el-radio label="已审核"></el-radio>
          <el-radio label="已关闭"></el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >提交</el-button
        >
      </el-form-item>
    </el-form>

    <locationAdd
      v-if="dialogVisible"
      :dialogVisible="dialogVisible"
      @dialogVisible="onDialogVisible"
      @rowClick="onRowClick"
    ></locationAdd>
  </div>
</template>
<script>
import locationAdd from "../../../../addAddress/location-add.vue";
export default {
  components: {
    locationAdd,
  },
  data() {
    return {
      ruleForm: {
        storeName: "",
        location: "",
        address: "",
        sort: "",
        status: "接单",
      },
      rules: {
        storeName: [{ required: true, message: "必填字段", trigger: "blur" }],
        location: [{ required: true, message: "必填字段", trigger: "blur" }],
        address: [{ required: true, message: "必填字段", trigger: "blur" }],
      },

      dialogVisible: false,
    };
  },
  mounted() {},
  computed: {},
  methods: {
    onClose(data) {
      this.$emit("close", data);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$http
            .post("/system/shop/save", {
              shop_name: this.ruleForm.storeName,
              region: this.ruleForm.location,
              location: this.ruleForm.address,
              sort: this.ruleForm.sort,
              status: this.ruleForm.status,
              commercial_id:
                localStorage.getItem("commercial_id") ||
                sessionStorage.getItem("commercial_id"),
              shopkeeper:
                localStorage.getItem("user_id") ||
                sessionStorage.getItem("user_id"),
            })
            .then((res) => {
              if (res.data.status == "success") {
                this.$emit("close");
                this.$message({
                  message: res.data.msg,
                  type: "success",
                });
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    onDialogVisible(data) {
      this.dialogVisible = data;
    },
    onRowClick(location, address) {
      this.ruleForm.location = location;
      this.ruleForm.address = address;
    },
  },
};
</script>
<style scoped></style>
