<template>
  <div>
    <div v-if="isShow == 0">
      <bread-crumb :crumbData="['店铺管理']" :chooseCrumbData="1"></bread-crumb>
      <search-case
        :searchData="searchData"
        @inquireBtnClick="inquireBtnClick"
      ></search-case>

      <div
        style="
          padding: 10px 20px;
          border-bottom: 1px solid #eee;
          display: flex;
          align-items: center;
        "
      >
        <div style="margin-right: 5px">
          <el-button type="primary" size="mini" @click="isShow = 1">
            <div style="display: flex; align-items: center; font-size: 14px">
              <img
                style="width: 16px; height: 16px"
                :src="require('@/assets/iconImg/logo.png')"
              />
              <span style="margin-left: 3px">添加店铺</span>
            </div>
          </el-button>
        </div>
        <div style="margin-right: 5px">
          <el-button type="danger" size="mini" @click="batchBtn">
            <div style="display: flex; align-items: center; font-size: 14px">
              <i class="el-icon-delete poiner"></i>
              <span style="margin-left: 3px">批量删除</span>
            </div>
          </el-button>
        </div>
      </div>

      <div style="padding: 20px">
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          border
          @selection-change="handleSelectionChange"
          style="width: 100%"
        >
          <el-table-column type="selection" width="44"> </el-table-column>
          <el-table-column prop="id" sortable label="ID"></el-table-column>
          <el-table-column
            prop="create_time"
            sortable
            label="创建日期"
          ></el-table-column>
          <el-table-column prop="shopkeeper" label="店主"></el-table-column>
          <el-table-column prop="shop_name" label="名称"></el-table-column>
          <el-table-column prop="region" label="所在地区"></el-table-column>
          <el-table-column prop="location" label="地址"></el-table-column>
          <el-table-column prop="status" label="状态"></el-table-column>
          <el-table-column label="管理">
            <template slot-scope="scope">
              <el-button plain @click="handleManage(scope.$index, scope.row)"
                >美团设置</el-button
              >
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <i class="el-icon-edit poiner" @click="handleEdit(scope.row)"></i>
              <span style="margin: 0 10px"></span>
              <i
                class="el-icon-delete poiner"
                @click="handleDelete(scope.row)"
              ></i>
            </template>
          </el-table-column>
        </el-table>
        <div class="tableBottom">
          <span style=""
            >显示第&nbsp;{{
              tableData.length > 0 ? (page - 1) * length + 1 : 0
            }}&nbsp;至&nbsp;{{
              (page - 1) * length + tableData.length
            }}&nbsp;项结果，共&nbsp;{{ tableFinshNum }}&nbsp;项</span
          >

          <div
            style="
              display: flex;
              justify-content: flex-end;
              align-items: center;
            "
          >
            <el-button>首页</el-button>
            <el-button>上页</el-button>
            <el-pagination
              background
              layout=" pager,slot"
              :total="tableFinshNum"
            ></el-pagination>
            <el-button>下页</el-button>
            <el-button>末页</el-button>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      title="美团店铺设置"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
    >
      <div>
        <div>
          <el-row>
            <el-col :span="10">
              <span>商品ID：</span>
              <span>{{ meituan.goodsId }}</span>
            </el-col>
            <el-col :span="10">
              <span>店铺名称：</span>
              <span>{{ meituan.storeName }}</span>
            </el-col>
          </el-row>
        </div>

        <div style="min-height: 300px; margin: 20px 0">
          <el-col :span="7">
            <span style="white-space: nowrap"
              >美团门店ID列表(多个","分割)：</span
            >
          </el-col>
          <el-col :span="10">
            <textarea
              :rows="10"
              :cols="50"
              v-model="meituan.storeId"
            ></textarea>
          </el-col>
        </div>
        <div style="display: flex; justify-content: flex-end; width: 100%">
          <el-button type="primary" size="mini">
            <div style="display: flex; align-items: center; font-size: 14px">
              <img
                style="width: 16px; height: 16px"
                :src="require('@/assets/iconImg/logo.png')"
              />
              <span style="margin-left: 5px">去美团授权</span>
            </div>
          </el-button>
          <el-button type="primary" size="mini">
            <div style="display: flex; align-items: center; font-size: 14px">
              <img
                style="width: 16px; height: 16px"
                :src="require('@/assets/iconImg/logo.png')"
              />
              <span style="margin-left: 5px">保存</span>
            </div>
          </el-button>
        </div>
      </div>
    </el-dialog>

    <add-group v-if="isShow == 1" @close="onClose"></add-group>
    <handle-amend
      v-if="isShow == 2"
      @close="onClose"
      :data="data"
    ></handle-amend>
  </div>
</template>
<script>
import addGroup from "./childrens/store-add.vue";
import handleAmend from "./childrens/handleAmend.vue";
export default {
  components: {
    addGroup,
    handleAmend,
  },
  data() {
    return {
      isShow: 0,

      searchData: {
        date: "",
        option1: "",
        selectPlace1: "状态",
        thirdSelectOptiones: ["待审核", "已审核", "已关闭"],
        QueryContent: "",
        inquirePlaceholder: "输入要查询的内容",
        isInquireBtn: true,
        isThirdSelect: true,
        isInquireValue: true,
      },

      tableData: [],
      multipleSelection: [],
      tableFinshNum: 0,

      dialogVisible: false,
      meituan: {
        goodsId: "",
        storeName: "",
        storeId: "",
      },
      page: 1,
      length: 10,
      commercialId: "",
      data: "",
    };
  },
  mounted() {
    this.commercialId =
      sessionStorage.getItem("commercial_id") ||
      localStorage.getItem("commercial_id");
    this.getTabList();
  },
  computed: {},
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleManage() {
      this.dialogVisible = true;
    },
    getTabList() {
      this.$http
        .post("/system/shop/lst", {
          currentLength: this.length,
          currentPage: this.page,
          commercial_id: this.commercialId,
        })
        .then((res) => {
          this.tableData = res.data.data;
          this.tableFinshNum = res.data.count;
        });
    },
    inquireBtnClick(data) {
      this.$http
        .post("/system/shop/search", {
          firstTime: data.dateRange[0],
          lastTime: data.dateRange[1],
          search: data.inquireValue,
          status: data.thirdSelect,
          commercial_id: this.commercialId,
        })
        .then((res) => {
          this.tableData = res.data.data;
          this.tableFinshNum = res.data.count;
        });
    },
    handleEdit(row) {
      this.isShow = 2;
      this.data = row;
    },
    batchBtn() {
      if (this.multipleSelection.length == 0) return;
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const arr = [];
          this.multipleSelection.forEach((item) => {
            arr.push(item.id);
          });
          this.$http
            .post("/system/shop/bacthDel", { idArr: arr })
            .then((res) => {
              if (res.data.status == "success") {
                this.$message({
                  message: res.data.msg,
                  type: "success",
                });
                this.getTabList();
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleDelete(row) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http.post("/system/shop/del", { id: row.id }).then((res) => {
            if (res.data.status == "success") {
              this.$message({
                message: res.data.msg,
                type: "success",
              });
              this.getTabList();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    onClose() {
      this.isShow = 0;
      this.getTabList();
    },

    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(() => {
          done();
        })
        .catch(() => {});
    },
  },
};
</script>
<style scoped lang="less">
.tableBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666;
  font-size: 14px;
}
/deep/ .cell {
  padding: 0 !important;
}
</style>
